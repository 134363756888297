<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="multipleItemsSelectedCount" class="side-panel-selected-count">
      <p>
        {{ __("Selected") }}
        <el-tag>
          <strong>{{ multipleItemsSelectedCount }}</strong>
        </el-tag>
        {{ __("items") }}.
      </p>
    </div>
    <div v-else class="side-panel-content-info">
      <div class="field-group">
        <div class="titles">{{ __("Name") }}</div>
        <div class="details">{{ contentForm.prompt_name }}</div>
      </div>

      <PromptVoiceInfo
        :voice_name="contentForm.default_voice_name"
        :tts_value="contentForm.default_tts_value"
        :audio_url="contentForm.default_voice_audio_url"
        :variables="variables"
      ></PromptVoiceInfo>
      <div
        v-for="prompt_value in contentForm.prompt_values"
        :key="prompt_value.prompt_value_id"
      >
        <PromptVoiceInfo
          :voice_name="getAccountTtsVoiceName(prompt_value.tts_voice_id)"
          :tts_value="prompt_value.tts_value"
          :audio_url="prompt_value.file_url"
          :variables="variables"
        ></PromptVoiceInfo>
      </div>
    </div>

    <div style="margin-top: 20px;" class="side-panel-action-list">
      <el-dropdown
        @command="command => handleAction(command)"
        placement="bottom-start"
      >
        <el-button plain class="btn-plain">{{ __("Actions") }}</el-button>
        <el-dropdown-menu slot="dropdown" style="width: 200px;">
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="edit"
            :disabled="!can('content.prompts.write')"
          >
            <span>{{ __("Edit") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="duplicate"
          >
            <span>{{ __("Duplicate") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            command="move"
            :disabled="!can('content.prompts.write')"
          >
            <span>{{ __("Move to folder") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="check_in_use"
            :disabled="!!contentFormInfo.is_refreshing"
          >
            <span>{{ __("Check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="refresh"
          >
            <span>{{ __("Refresh check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            :disabled="!can('content.prompts.write')"
            command="delete"
          >
            <span>{{ __("Delete") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
      :modal="false"
    >
      <el-form
        ref="contentForm"
        :rules="rules"
        :model="contentForm"
        label-position="top"
        size="large"
      >
        <el-row
          type="flex"
          style="padding-top: 20px;display: flex;justify-content: center"
        >
          <el-col :lg="15" :md="19" :sm="22">
            <page-header :title="__('Prompt')" :content-id="this.id" />
          </el-col>
        </el-row>
        <el-row type="flex" style="display: flex;justify-content: center">
          <el-col :lg="15" :md="19" :sm="22">
            <el-form-item prop="prompt_name" :label="__('Name')">
              <el-input v-model="contentForm.prompt_name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" style="display: flex;justify-content: center">
          <el-col :lg="15" :md="19" :sm="22">
            <prompt-voice-edit
              ref="promptVoiceEditRef_default"
              :voice-name="contentForm.default_voice_name"
              :file-url="contentForm.default_voice_audio_url"
              :file-path="contentForm.default_voice_file_path"
              @update-tts="handleTTSUpdate($event)"
              :tts_value_prop="contentForm.default_tts_value"
              :voice-id="-1"
              @update-audio-url="handleURLUpdate($event)"
              :folder="folder"
              @deleteUploadedAudio="deleteUploadedAudio"
              :file-name="contentForm.default_voice_uploaded_file_name"
              :well-said-voices="wellSaidVoices"
            ></prompt-voice-edit>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          v-for="prompt_value in contentForm.prompt_values"
          :key="prompt_value.prompt_value_id"
          style="display: flex;justify-content: center"
        >
          <el-col :lg="15" :md="19" :sm="22">
            <prompt-voice-edit
              :ref="`promptVoiceEditRef_${prompt_value.prompt_value_id}`"
              :voice-name="getAccountTtsVoiceName(prompt_value.tts_voice_id)"
              :file-url="prompt_value.file_url"
              :file-path="prompt_value.audio_file_path"
              style="margin-top: 20px; margin-right: 5px"
              @update-tts="handleTTSUpdate($event)"
              :tts_value_prop="prompt_value.tts_value"
              :voice-id="prompt_value.tts_voice_id"
              @update-audio-url="handleURLUpdate($event)"
              :folder="folder"
              @remove-voice="removeVoice"
              :file-name="prompt_value.uploaded_file_name"
              :provider="getAccountTtsVoiceProvider(prompt_value.tts_voice_id)"
              @deleteUploadedAudio="deleteUploadedAudio"
              :well-said-voices="wellSaidVoices"
            ></prompt-voice-edit>
          </el-col>
        </el-row>
        <el-row type="flex" style="display: flex;justify-content: center">
          <el-col :lg="15" :md="19" :sm="22">
            <el-form-item class="form_label_top" style="margin-top: 20px">
              <tts-dropdown-detail
                :default_tts_id="this.ttsVoiceId"
                set-prompt
                v-loading="loadingVoices"
                @tts-changed="addVoice"
                :clearable="true"
                @clear="this.ttsVoiceId = ''"
                popper-class="voicesList"
              ></tts-dropdown-detail>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-row type="flex" style="display: flex;justify-content: center">
          <el-col :lg="15" :md="19" :sm="22">
            <save-button
              :disabled="!contentUpdated || !can('content.prompts.write')"
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
              variant="CreateUpdate"
            />

            <el-button @click="handleCancel" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapState, mapActions, mapGetters } from "vuex";
import PromptVoiceEdit from "@/views/build/content/prompts/components/PromptVoiceEdit";
import _ from "lodash";
import PromptVoiceInfo from "@/views/build/content/prompts/components/PromptVoiceInfo";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { validatePrompt } from "@/api/prompts";
import { removeFiles } from "@/api/services";
import TtsDropdownDetail from "@/views/build/content/prompts/components/TtsDropdownDetail";
import {
  CHECK_IN_USE_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  MOVE_ACTION,
  REFRESH_ACTION,
  DUPLICATE_ACTION
} from "@/constants/contents";

export default {
  mixins: [BaseContentInfoPanel, BaseContent],
  components: {
    PageHeader,
    SaveButton,
    PromptVoiceEdit,
    PromptVoiceInfo,
    TtsDropdownDetail
  },
  data() {
    let validatePromptName = async (rule, value, callback) => {
      let hasError = false;
      await validatePrompt(value)
        .then(res => {
          if (res.data.found && value !== this.contentFormInfo.prompt_name) {
            hasError = true;
          }
        })
        .catch(() => {
          hasError = false;
        });

      if (hasError) {
        callback(__("Prompt already exists"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        prompt_name: [
          { required: true, trigger: "blur", message: __("Name is required") },
          { validator: validatePromptName, trigger: "blur" }
        ]
      },
      ttsVoiceId: "",
      isSubmitting: false,
      folder: "prompt_audio",
      additionalValidateRoute: "prompts",
      isContent: true,
      viewMode: "allow_read",
      promptsToDelete: [],
      forceDeleteEventName: "initiate-force-delete-Prompt"
    };
  },
  computed: {
    ...mapState("tasks", {
      loadingVoices: state => state.loading,
      languages: state => state.languages
    }),
    ...mapState("wslprofiles", {
      wslProfilesVoices: state => state.wslProfilesVoices
    }),
    ...mapGetters("variables", {
      variables: "variables"
    }),
    ...mapState("folders", {
      selectedFolderId: state => state.selectedFolderId
    }),
    ...mapGetters("accountTtsVoices", {
      getAccountTtsVoiceName: "getAccountTtsVoiceName",
      getAccountTtsVoiceProvider: "getAccountTtsVoiceProvider"
    }),
    wellSaidVoices() {
      return _.flatMap(this.wslProfilesVoices);
    }
  },
  methods: {
    ...mapActions("prompts", {
      createPrompt: "createPrompt",
      updatePrompt: "updatePrompt",
      deleteContentMethod: "deletePrompt",
      undoDeleteContent: "undoDeletePrompt",
      duplicatePrompt: "duplicatePrompt",
      refreshPrompt: "refreshPrompt"
    }),
    async addVoice(tts_voice) {
      this.ttsVoiceId = tts_voice;
      let voiceList = _.flatMap(this.languages);
      let voice = _.find(
        voiceList,
        voice => voice.tts_voice_id === this.ttsVoiceId
      );
      if (
        _.some(this.contentForm.prompt_values, prompt_value => {
          return prompt_value.tts_voice_id === this.ttsVoiceId;
        })
      ) {
        this.$message.error(
          __("The voice :voice_name is already in the list", {
            voice_name: voice.voice_name
          })
        );
      } else {
        this.contentForm.prompt_values.push({
          tts_voice_id: this.ttsVoiceId,
          tts_value: "",
          file_url: "",
          voice_name: voice.voice_name,
          file_path: "",
          provider: voice.provider
        });
      }
      this.ttsVoiceId = "";
    },
    removeVoice(voiceId) {
      let prompt_value_index = _.findIndex(
        this.contentForm.prompt_values,
        prompt_value => {
          return prompt_value.tts_voice_id === voiceId;
        }
      );
      if (prompt_value_index !== -1) {
        this.contentForm.prompt_values.splice(prompt_value_index, 1);
      }
    },
    deleteUploadedAudio(voiceId) {
      if (voiceId === -1) {
        this.promptsToDelete.push(this.contentForm.default_voice_file_path);
        this.contentForm.default_voice_audio_url = "";
        this.contentForm.default_voice_file_path = "";
      } else {
        let prompt_value = _.find(
          this.contentForm.prompt_values,
          prompt_value => {
            return prompt_value.tts_voice_id === voiceId;
          }
        );
        if (prompt_value) {
          this.promptsToDelete.push(prompt_value.audio_file_path);
          prompt_value.file_path = "";
          prompt_value.file_url = "";
          prompt_value.audio_file_path = "";
        }
      }
    },
    validatePromptValues() {
      let isValid = true;
      const promptVoiceEditRef = this.$refs["promptVoiceEditRef_default"];
      if (promptVoiceEditRef) {
        promptVoiceEditRef.validateVoiceContent();
        if (!_.isEmpty(promptVoiceEditRef.voice_content_error)) {
          isValid = false;
        }
      }

      let promptValues = _.cloneDeep(this.contentForm.prompt_values);
      promptValues.forEach(prompt_value => {
        const promptVoiceEditRef = this.$refs[
          `promptVoiceEditRef_${prompt_value.prompt_value_id}`
        ];
        if (promptVoiceEditRef) {
          promptVoiceEditRef[0].validateVoiceContent();
          if (!_.isEmpty(promptVoiceEditRef[0].voice_content_error)) {
            isValid = false;
          }
        }
      });
      return isValid;
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid && this.validatePromptValues()) {
          this.isSubmitting = true;
          this.contentForm.folder_id = this.selectedFolderId;
          const process =
            this.id === -1 ? this.createPrompt : this.updatePrompt;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("Prompt added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Prompt updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              if (!_.isEmpty(this.promptsToDelete)) {
                removeFiles(this.promptsToDelete);
              }
              this.handleCancel();
            })
            .catch(err => {
              // console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },
    handleTTSUpdate({ tts_value, voiceId }) {
      if (voiceId === -1) {
        this.contentForm.default_tts_value = tts_value;
      } else {
        let prompt_value = _.find(
          this.contentForm.prompt_values,
          prompt_value => {
            return prompt_value.tts_voice_id === voiceId;
          }
        );
        if (prompt_value) {
          prompt_value.tts_value = tts_value;
        }
      }
    },
    handleURLUpdate({ url, voiceId, path, uploadedFileName }) {
      if (voiceId === -1) {
        this.contentForm.default_voice_audio_url = url;
        this.contentForm.default_voice_file_path = path;
        this.contentForm.default_voice_uploaded_file_name = uploadedFileName;
      } else {
        let prompt_value = _.find(
          this.contentForm.prompt_values,
          prompt_value => {
            return prompt_value.tts_voice_id === voiceId;
          }
        );
        if (prompt_value) {
          prompt_value.file_url = url;
          prompt_value.audio_file_path = path;
          prompt_value.uploaded_file_name = uploadedFileName;
        }
      }
    },

    openMoveToFolderDialog() {
      let content_ids = [this.contentForm.prompt_id];
      if (this.checkedContents.length) {
        content_ids = this.checkedContents.map(item => {
          if (item && item.prompt_id) {
            return item.prompt_id;
          }
        });
      }
      EventBus.$emit("open-move-to-folder-modal", {
        content_ids: [...content_ids],
        content_model: "Prompt"
      });
    },

    openCheckInUseDialog() {
      EventBus.$emit("open-check-in-use-modal", {
        content_model: "Prompt",
        content_id: this.contentForm.prompt_id
      });
    },
    handleDuplicate(prompt) {
      this.duplicatePrompt(prompt)
        .then(data => {
          this.$message({
            message: data.message || __("Operation Successful"),
            type: "success"
          });

          EventBus.$emit("list-changed");
        })
        .catch(() => {
          this.$message({
            message: __("something went wrong"),
            type: "error"
          });
        });
    },
    refreshCheckInUse() {
      // call backend api
      EventBus.$emit("call-refresh-check-in-use-job", {
        content_model: "Prompt",
        content_id: this.contentForm.prompt_id,
        content_name: this.contentForm.prompt_name,
        refresh: this.refreshPrompt
      });
    },

    handleAction(command) {
      switch (command) {
        case EDIT_ACTION:
          this.handleEdit();
          break;
        case MOVE_ACTION:
          this.openMoveToFolderDialog();
          break;
        case CHECK_IN_USE_ACTION:
          this.openCheckInUseDialog();
          break;
        case DELETE_ACTION:
          this.handleDelete(
            this.contentForm,
            "Prompt",
            this.contentForm.prompt_id
          );
          break;
        case REFRESH_ACTION:
          this.refreshCheckInUse();
          break;
        case DUPLICATE_ACTION:
          this.handleDuplicate(this.contentForm);
          break;
      }
    }
  },

  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });
  },
  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
  }
};
</script>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
<style lang="scss">
.editContentItemModal {
  padding: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
