<template>
  <div class="content-component" @click="handleClearSelection">
    <el-button
      :disabled="!can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      @click.stop="createContentItem"
      >{{ __("Create Prompt") }}</el-button
    >
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :show-content-view-switcher="true"
            :content-api="contentAPI"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar v-loading="loading" :native="true">
          <div class="contentCardsContainer">
            <el-row
              :class="contentViewClasses"
              v-if="prompts.length"
              v-show="can('content.' + additionalValidateRoute + '.read')"
            >
              <content-list-item
                :content-view-type="contentViewType"
                v-for="(prompt, index) in prompts"
                :item-index="index"
                :key="'_content_prompt_' + index"
                :draggable-data="{
                  content_id: selectedItems.length
                    ? selectedIds
                    : [prompt[primaryKey]],
                  content_type: contentType
                }"
                :allow-multi-select="true"
                :card-class="{ selected: selectedItemIndex === index }"
                icon="icon-microphone.svg"
                :card-text="prompt.prompt_name"
                :content-list-item="prompt"
                :fields-to-display="displayFields"
                @delete="handleDelete(prompt, 'Prompt', prompt.prompt_id)"
                @select="handleSelect(index, prompt)"
                @edit="handleEdit(index, prompt)"
                @checked-content="handleSelectMultiple($event)(prompt)"
                @toggle-select-all="
                  handleToggleSelectAll(prompts, ...arguments)
                "
                :is-all-selected="isAllSelected"
                :total-items-selected="selectedItems.length"
              >
                <template #default_tts_value>
                  <div class="text-wrap-anywhere">
                    {{ get_tts_value(prompt.default_tts_value) }}
                  </div>
                  <audio-player
                    v-if="prompt.default_voice_audio_url"
                    :file="prompt.default_voice_audio_url"
                    :simple-player="true"
                    :icon-play="
                      require('@/assets/icons/content-icon-prompt-play-audio.svg')
                    "
                    class="content-list-simple-player"
                  ></audio-player>
                </template>
              </content-list-item>
            </el-row>
            <el-row
              v-else-if="!loading"
              style="padding-top: 100px;"
              type="flex"
              justify="center"
            >
              {{ __("No Prompts") }}
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentListItem from "@/views/build/content/mixins/ContentListItem";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  name: "PromptsContentsList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentListItem,
    AudioPlayer
  },
  data() {
    return {
      additionalValidateRoute: "prompts",
      sortByColumns: ["prompt_name", "prompt_id"],
      isContent: true,
      viewMode: "allow_read",
      displayFields: [
        {
          field_name: "prompt_name",
          display_name: __("Name")
        },
        {
          field_name: "default_tts_value",
          display_name: __("Default Voice"),
          use_slot: true
        }
      ]
    };
  },

  computed: {
    ...mapState("prompts", {
      prompts: state => state.prompts,
      loading: state => state.loading
    }),
    ...mapGetters("variables", {
      variables: "variables"
    }),
    selectedIds() {
      return _.map(this.selectedItems, this.primaryKey);
    }
  },

  methods: {
    ...mapActions("prompts", {
      contentAPI: "getPrompts"
    }),

    get_tts_value(tts) {
      _.map(this.variables, variable => {
        if (!_.isEmpty(variable)) {
          let re = new RegExp(`{{${variable.variable_id}\\|variable}}`, "g");
          tts = tts.replace(re, `{{${variable.variable_name}}}`);
        }
      });
      return tts;
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: #4db3f6 !default;
$content-theme-hover-color: dodgerblue !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";

.content-list-simple-player {
  background-color: transparent !important;

  > .audio-bar-contents {
    height: 100% !important;
  }
}
</style>

<style lang="scss">
.content-prompt-list {
  .default_tts_value {
    display: none !important;
  }
}

.text-wrap-anywhere {
  overflow-wrap: anywhere;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
