<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "PromptsContentsIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("prompts", {
      contents: state => state.prompts
    }),
    ...mapGetters("app", {
      selectedServiceProviderAccountCombination:
        "selectedServiceProviderAccountCombination"
    })
  },

  methods: {
    ...mapActions("tasks", {
      getLanguages: "getLanguages"
    }),
    ...mapActions("wslprofiles", {
      getWslProfilesVoices: "getWslProfilesVoices"
    }),
    ...mapActions("variables", {
      fetchVariables: "forceFetchVariables"
    }),
    initContent() {
      return {
        prompt_name: "",
        prompt_values: [],
        default_tts_value: "",
        default_voice_name: __("Default Voice"),
        default_voice_audio_url: "",
        default_voice_file_path: "",
        default_voice_uploaded_file_name: ""
      };
    }
  },

  mounted() {
    this.fetchVariables();
    this.getLanguages();
    this.contentType = "Prompt";
    this.primaryKey = "prompt_id";
  },
  watch: {
    selectedServiceProviderAccountCombination: {
      immediate: true,
      async handler() {
        this.getWslProfilesVoices();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
