<template>
  <div class="field-group">
    <div class="titles">
      {{ voice_name }}
    </div>
    <div class="details">
      {{ get_tts_value }}
    </div>
    <div v-if="audio_url" class="flex">
      <AudioPlayer
        v-if="audio_url"
        :file="audio_url"
        :simple-player="true"
        :icon-play="
          require('@/assets/icons/content-icon-prompt-play-audio.svg')
        "
        :icon-download="require('@/assets/icons/icon-download.svg')"
        icon-pause="/icons/pause.svg"
        class="simple-player"
      ></AudioPlayer>
    </div>
  </div>
</template>

<script>
import AudioPlayer from "@/components/AudioPlayer";
import _ from "lodash";
export default {
  name: "PromptVoiceInfo",
  components: {
    AudioPlayer
  },
  props: {
    voice_name: {
      type: String,
      required: false,
      default: __("Default Voice")
    },
    tts_value: {
      type: String,
      required: false,
      default: ""
    },
    audio_url: {
      type: String,
      required: false,
      default: ""
    },
    variables: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    }
  },

  computed: {
    get_tts_value() {
      let tts = this.tts_value;
      _.map(this.variables, variable => {
        if (!_.isEmpty(variable)) {
          let re = new RegExp(`{{${variable.variable_id}\\|variable}}`, "g");
          tts = tts.replace(re, `{{${variable.variable_name}}}`);
        }
      });
      return tts;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/content-edit-info.scss";

.simple-player {
  background-color: transparent !important;

  > .audio-bar-contents {
    height: 100% !important;
  }
}
</style>
