<template>
  <div
    style="min-width: 920px;padding-bottom: 20px;display: flex;flex-direction: column"
  >
    <el-row type="flex">
      <el-col :span="17">
        <div
          class="el-form-item__label required"
          v-if="!audioFileOnly && voiceId === -1"
        >
          {{ voiceName }}
        </div>
        <div class="el-form-item__label" v-else-if="!audioFileOnly">
          {{ voiceName }}
        </div>
      </el-col>
      <el-col :span="1">
        <div>
          <el-button
            plain
            v-if="voiceId !== -1 || !voiceId"
            style="padding-left: 20px;border: none;"
            @click="handleRemoveVoice"
          >
            <i
              class="el-icon-close"
              style="font-weight: bold; margin-top: 10px"
            ></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-form-item prop="voice_content" :error="voice_content_error">
      <el-row
        type="flex"
        justify="space-between"
        style="display: flex;align-items: center"
      >
        <el-col :span="18" v-if="!audioFileOnly">
          <input-variable-popper
            :value="tts_value"
            is-content-editable
            @input="updateParent($event)"
            @content-wo-ssml="updateContentWithoutSSML($event)"
            :is-text-area="false"
            :ats="['{{']"
            include-audio-variables
            :include-secure-variables="false"
            popper-class="prompt-node-popper"
            :has-error="hasError"
            :class="
              !(provider === 'lumenvox') ? 'ttsEditor' : 'defaultTtsEditor'
            "
            :popper-offset="-400"
            :showSSMLEditor="!(provider === 'lumenvox')"
            scroll-ref=".editContentItemModal"
          />
          <AudioPlayer
            class="audio-player"
            :disabled="!tts_value"
            @get-preview="generateTTSAudio"
            :show-reload="ttsContentChanged"
            :generating-audio="generatingAudio"
            :file="previewAudioFile"
            v-if="!(provider === 'lumenvox')"
          ></AudioPlayer>
          <div class="el-form-item__error" v-if="fileNotDownloadable">
            {{ __("No audio file generated") }}
          </div>
        </el-col>
        <el-col :span="6" style="margin-top:-35px">
          <div
            style="font-weight: bold; margin-bottom: 5px;display: flex;justify-content: center"
            v-if="!audioFileOnly"
          >
            {{ __("Audio file (optional)") }}
          </div>
          <div v-if="fileUrl">
            <div
              style="display: flex;align-items: center;justify-content: center"
            >
              <div
                style="height: 120px; width: 140px; background: var(--theme-color);display: flex;align-items: center;justify-content: center;border-radius: 6px;position:relative"
              >
                <div
                  style="position:absolute;right: 5px; top:5px"
                  class="downloadAudio"
                  @click="downloadPromptAudio"
                >
                  <!--                <a :href="fileUrl" target="_blank" download="test.wav">-->
                  <img
                    :src="require('@/assets/icons/icon-download.svg')"
                    height="24px"
                    alt="download_audio"
                    onload="SVGInject(this)"
                  />
                  <!--                </a>-->
                </div>

                <div style="display: flex; flex-direction: column">
                  <div
                    style="display: flex; justify-content: center; align-items: center"
                  >
                    <AudioPlayer
                      :file="fileUrl"
                      :simple-player="true"
                      class="simple-player"
                      style="display: inline-block;width: 32px"
                    ></AudioPlayer>
                  </div>
                  <div
                    style="color: white; max-width: 100px; text-overflow: ellipsis ;white-space: nowrap;overflow: hidden;font-size: 0.8em; margin-top: 5px"
                  >
                    {{ fileName }}
                  </div>
                </div>
              </div>
            </div>
            <div
              style="display: flex; justify-content: center; align-items: center"
            >
              <el-button
                plain
                size="small"
                style="border: none"
                @click="handleDelete"
                >{{ __("Delete") }}
              </el-button>
            </div>
          </div>
          <div v-else>
            <el-upload
              :class="
                audioFileOnly ? 'file-uploader-audio-only' : 'file-uploader'
              "
              v-loading="generatingWSAudio"
              :headers="AcHeaders"
              :action="getUploadUrl"
              :data="formData"
              :show-file-list="true"
              :on-success="handleSuccess"
              :on-error="handleError"
              :before-upload="beforeFileUpload"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :file-list="audioFileList"
              :limit="1"
              :multiple="false"
              accept="audio/wav, audio/mpeg"
              drag
            >
              <UploaderInfoIcon :type="promptMissing ? 'warning' : 'default'" />
              <div
                class="el-upload__tip"
                slot="tip"
                style="word-break: keep-all; text-align: center; text-align-last: center; line-height: 20px;"
              >
                <span v-if="promptMissing" style="color: red">
                  {{ __("Attachment is missing") }}
                </span>
                <span v-else>
                  {{ __("wav/mp3 files with a size less than 10mb") }}
                </span>
              </div>
            </el-upload>
          </div>
          <div
            v-if="showWSLVoiceSection"
            style="margin-top: 15px;display: flex; justify-content: center;"
          >
            <el-popover
              placement="right"
              width="260"
              trigger="click"
              v-model="selectedProfile"
            >
              <div class="contentListContainer">
                <well-said-lab-voices
                  :voices="wellSaidVoices"
                  @voice-click="generateWellSaidAudio($event)"
                />
              </div>
              <div id="wellsaid-btn" slot="reference">
                <el-button>{{ __("Virtual Voice Over") }}</el-button>
              </div>
            </el-popover>
          </div>
        </el-col>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
import BaseUploader from "@/components/uploaders/BaseUploader";
import AudioPlayer from "@/components/AudioPlayer";
import { getAudioPrompt } from "@/api/services";
import { getWslAudio } from "@/api/wslprofiles";
import { getPromptDownload } from "@/api/prompts";
import FileDownloader from "@/mixins/FileDownloader";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import WellSaidLabVoices from "@/views/build/content/prompts/components/WellSaidLabVoices";
import { mapGetters } from "vuex";
import _ from "lodash";
import UploaderInfoIcon from "@/components/uploaders/UploaderInfoIcon.vue";

export default {
  name: "PromptVoiceEdit",
  components: {
    WellSaidLabVoices,
    AudioPlayer,
    InputVariablePopper,
    UploaderInfoIcon
  },
  mixins: [BaseUploader, FileDownloader],
  props: {
    voiceName: {
      type: String,
      required: true,
      default: __("Default Voice")
    },
    fileUrl: {
      type: String,
      required: true,
      default: ""
    },
    filePath: {
      type: String,
      required: true,
      default: ""
    },
    fileName: {
      type: String,
      required: false,
      default: ""
    },
    tts_value_prop: {
      type: String,
      required: true,
      default: ""
    },
    voiceId: {
      type: Number,
      required: false
    },
    audioFileOnly: {
      type: Boolean,
      default: false
    },
    provider: {
      type: String,
      default: "google"
    },
    wellSaidVoices: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ttsFolder: "tts_audio",
      audioFileList: [],
      rules: {
        tts_value: [
          { required: true, trigger: "blur", message: __("TTS is required") }
        ]
      },
      audioFileUrl: "",
      ttsAtAudioFileCreation: "",
      generatingAudio: false,
      generatedAudio: false,
      generatingWSAudio: false,
      selectedProfile: false,
      previewAudioFile: "",
      tts_value: "",
      ttsValueWithoutSSML: "",
      voice_content_error: ""
    };
  },
  computed: {
    promptMissing() {
      return !!this.filePath && !this.fileUrl;
    },
    ttsContentChanged() {
      return this.tts_value !== this.ttsAtAudioFileCreation;
    },
    fileNotDownloadable() {
      return this.generatedAudio && _.isEmpty(this.previewAudioFile);
    },
    showWSLVoiceSection() {
      return this.wellSaidVoices.length > 0;
    },
    hasError() {
      return this.voice_content_error.length > 0;
    },
    ...mapGetters("variables", {
      variables: "variables",
      variableNames: "variableNames"
    })
  },
  methods: {
    beforeFileUpload(file) {
      if (file.type === "audio/wav" || file.type === "audio/mpeg") {
        if (file.size > 10 * 1024 * 1024) {
          this.$message.error(
            // eslint-disable-next-line
            __("File size should be less than 10MB. Please upload a smaller file.")
          );
          return false;
        }
        return true;
      } else {
        this.$message.error(
          __("File format not supported only please upload mp3 or wav file")
        );
        return false;
      }
    },
    validateVoiceContent() {
      if (this.voiceName === __("Default Voice")) {
        if (_.isEmpty(this.tts_value)) {
          this.$set(
            this,
            "voice_content_error",
            __("Text-to-Speech cannot be empty. Enter text for Default Voice.")
          );
        } else {
          this.$set(this, "voice_content_error", "");
        }
      } else {
        if (_.isEmpty(this.tts_value) && _.isEmpty(this.filePath)) {
          this.$set(
            this,
            "voice_content_error",
            // eslint-disable-next-line
            __("Text-to-Speech cannot be empty. Enter text or upload an Audio File.")
          );
        } else {
          this.$set(this, "voice_content_error", "");
        }
      }
    },
    handleSuccess(url) {
      this.audioFileUrl = url.url;
      this.$emit("update-audio-url", {
        url: this.audioFileUrl,
        voiceId: this.voiceId,
        path: url.path,
        uploadedFileName: url.uploaded_file_name
      });
      this.$set(this, "voice_content_error", "");
    },

    handleError(err) {
      console.log(err);
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    updateParent(value) {
      this.tts_value = value;
      this.$emit("update-tts", {
        tts_value: this.tts_value,
        voiceId: this.voiceId
      });
      this.validateVoiceContent();
    },
    handleChange(file, fileList) {
      this.$emit("on-change", file, fileList);
    },
    downloadPromptAudio() {
      getPromptDownload(this.filePath)
        .then(response => {
          this.downloadFile(response.data, `${this.fileName}`);
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.message
          });
        });
    },
    generateTTSAudio() {
      if (this.tts_value) {
        this.generatingAudio = true;
        this.generatedAudio = false;
        getAudioPrompt({
          prompt_text: this.tts_value,
          tts_voice_id: this.voiceId
        })
          .then(({ data }) => {
            this.previewAudioFile = data.audio_url;
            this.ttsAtAudioFileCreation = this.tts_value;
            this.generatingAudio = false;
            this.generatedAudio = true;
          })
          .catch(err => {
            this.generatingAudio = false;
            this.generatedAudio = false;
            this.$message({
              type: "error",
              message: err.message
            });
          });
      } else {
        this.previewAudioFile = "";
      }
    },
    generateWellSaidAudio(event) {
      let selectedVoice = event.voice;
      let profile_id = _.get(event.profile, "wsl_profile_id", -1);
      if (this.ttsValueWithoutSSML) {
        this.beforeGettingWSLAudio();
        getWslAudio({
          prompt_text: this.ttsValueWithoutSSML,
          speaker_id: selectedVoice.speaker_id,
          speaker_name: selectedVoice.speaker_name,
          ...(profile_id !== -1 && { wsl_profile_id: profile_id })
        })
          .then(({ data }) => {
            const filePath = data.file_path;
            const fpChunks = _.split(filePath, "/");

            if (filePath && fpChunks.length >= 2) {
              let speakerName = fpChunks[fpChunks.length - 2];
              speakerName += ".wav";
              const urlObj = {
                url: data.file_url,
                path: filePath,
                uploaded_file_name: speakerName
              };
              this.handleSuccess(urlObj);
              this.$message({
                type: "success",
                // eslint-disable-next-line
                message: __("Audio generated sucessfully for speaker: :speakerName", {
                    speakerName: speakerName
                  }
                )
              });
            }
            this.ttsAtAudioFileCreation = this.tts_value;
            this.generatingWSAudio = false;
          })
          .catch(err => {
            this.generatingWSAudio = false;
            this.$message({
              type: "error",
              message: err.message
            });
          });
      } else {
        this.previewAudioFile = "";
        this.selectedProfile = false;
        this.$message({
          type: "info",
          message: __("Text content required to preview audio")
        });
      }
    },
    handleRemoveVoice() {
      this.$emit("remove-voice", this.voiceId);
    },
    handleDelete() {
      this.file_url = "";
      this.$emit("deleteUploadedAudio", this.voiceId);
      this.validateVoiceContent();
    },
    beforeGettingWSLAudio() {
      this.$message({
        type: "info",
        message: __("Generating audio for selected WellSaid speaker")
      });
      this.generatingWSAudio = true;
      this.selectedProfile = false;
      this.handleDelete();
    },
    updateContentWithoutSSML(val) {
      let re = new RegExp("<[^>]*>?", "g");
      this.ttsValueWithoutSSML = val.replace(re, "").replace(/&nbsp;/g, "");
    }
  },

  mounted() {
    this.tts_value = this.tts_value_prop;
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";

.downloadAudio {
  cursor: pointer;

  ::v-deep svg {
    fill: white !important;

    path {
      fill: white !important;
    }
  }
}

.file-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;

  ::v-deep .el-upload-dragger {
    width: 140px;
    height: 140px;
    padding: 5px;
  }
}

.file-uploader-audio-only {
  display: flex;
  flex-direction: column;

  ::v-deep .el-upload-dragger {
    width: 140px;
    height: 120px;
    padding: 5px;
  }
}

.audio-player {
  border-radius: 0 0 6px 6px;
  width: 100%;
  min-width: 682px;
}

.simple-player {
  border-radius: 16px;
  display: inline-block;
  background: white;

  ::v-deep img {
    filter: invert(58%) sepia(52%) saturate(1516%) hue-rotate(166deg)
      brightness(96%) contrast(96%);
  }
}

.ttsEditor {
  ::v-deep .editableContent {
    height: 120px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;

    &:focus {
      border: 1px solid black;
    }
  }
}

.defaultTtsEditor {
  ::v-deep .editableContent {
    height: 120px;
    border-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;

    &:focus {
      border: 1px solid black;
    }
  }
}

.contentListContainer {
  max-height: 250px;
}

.required::before {
  content: "*";
  color: var(--theme-color);
  vertical-align: sub;
  margin-right: 2px;
}

.wellsaid-btn {
  cursor: pointer;
  width: 45%;
  margin: auto;
  padding: 10px;
  border-radius: 8px;
  //background: linear-gradient(270deg, #8e44ad 0%, #2c3e50 100%);
  border: 2px solid rgba(187, 246, 211, 0.23);
  //
  //&:hover,
  //&:focus {
  //  box-shadow: 1px 1px 7px 0px #bfffd9;
  //  background: #414063;
  //  border: 2px solid #bfffd9;
  //}
}

//.wellsaid-img {
//  height: 14px;
//  width: 100%;
//  align-content: center;
//  padding-top: 5px;
//}
</style>
