<template>
  <div style="margin-bottom:10px;" v-if="voices.length">
    <div>
      <el-input
        type="text"
        clearable
        v-model="search"
        :placeholder="__('Search')"
      />
    </div>
    <el-scrollbar :native="false">
      <div style="height: 22vh; margin-top: 3px">
        <div v-for="(item, index) in voices" :key="index">
          <div class="header">
            {{ formatGroupName(item.profile_name) }}
          </div>
          <div>
            <div
              v-for="(voice, index) in filteredVoices(item.profile_speakers)"
              :key="index"
              class="voices-container"
              @click="handleVoiceClick(voice, item)"
            >
              <div class="voices-content">
                <div style="display:flex">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="__('Powered by WellSaid Labs')"
                    placement="top"
                    :open-delay="300"
                  >
                    <img
                      :src="
                        require('@/assets/icons/sidebar/icon-well-said-labs.svg')
                      "
                      height="24px"
                      width="24px"
                      style="padding-right: 15px"
                    />
                  </el-tooltip>
                  <div>
                    {{ getSpeakerName(voice) }}<br />
                    {{ getSpeakerVoice(voice) }}
                  </div>
                </div>
                <div class="speaker-gender">
                  <font-awesome-icon
                    v-if="voice.gender === 'M'"
                    :icon="['fas', 'male']"
                    :class="`fa-lg male-icon`"
                  />
                  <font-awesome-icon
                    v-else-if="voice.gender === 'F'"
                    :icon="['fas', 'female']"
                    :class="`fa-lg female-icon`"
                  />
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    voices: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    filteredVoices() {
      return item => {
        return item.filter(voice => {
          return (
            voice.speaker_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            voice.speaker_id
              .toString()
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
      };
    }
  },
  methods: {
    getSpeakerName(voice) {
      let speakerName = voice.speaker_name;
      return _.upperFirst(speakerName);
    },
    getSpeakerVoice(voice) {
      let speakerName = voice.speaker_voice;
      return _.upperFirst(speakerName);
    },
    formatGroupName(group_name) {
      return _.upperFirst(group_name);
    },
    handleVoiceClick(voice, profile) {
      this.$emit("voice-click", { voice: voice, profile: profile });
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  padding: 5px 0px;
  color: var(--theme-color) !important;
  font-weight: 600;
}
.el-divider--horizontal {
  background: #f7f7f7cf !important;
  margin: 0px !important;
}
.voices-container {
  cursor: pointer;
  &:hover {
    background: #0e0e0e0d;
  }
}
.voices-content {
  display: flex;
  padding: 10px 4px;
  justify-content: space-between;
}
.speaker-gender {
  margin-right: 3px;
  font-weight: 700;
  color: #0e0e0e7a;
}
.male-icon {
  color: #93b1f7;
}
.female-icon {
  color: #f107315c;
}
::v-deep .el-scrollbar__thumb {
  height: 0 !important;
}
</style>
